import styled from "styled-components";
import theme from "../../../utils/theme";

type HamburgerProps = {
  $showhamburger?: boolean;
  color: string;
};

type BackButtonProps = {
  $showbutton?: boolean;
  $genericVariant?: boolean;
};

type NavBarContainerProps = {
  $genericVariant?: boolean;
};

type MobileMenuProps = {
  open?: boolean | null;
  $ismobile?: boolean | null;
};

const BackButton = styled.button<BackButtonProps>`
  ${(props) =>
    props.$showbutton === true || props.$showbutton === null
      ? ""
      : "display: none;"}
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 10px;
  // ${(props) => (props.$genericVariant ? "top: 75%;" : "top: 50%;")}
  transform: translateY(-50%);
`;

const NavbarWrapper = styled.div`
  position: fixed;
  padding-bottom: 0px;
  z-index: 998;
`;
const NavbarContainer = styled.div<NavBarContainerProps>`
  ${(props) => (props.$genericVariant ? "padding-top: 50px;" : undefined)}
  min-height: 50px;

  width: 100%;
  //color: #0a165a;
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 998;

  > div:nth-child(2) {
    text-align: center;
    width: 100vw;
  }
`;
export const ChildrenContentContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const NavLink = styled.a`
  display: flex;
  gap: 5px;
  color: #0a165a;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: grey;
  }
`;

const NavLinkItem = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

const HamburgerMenu = styled.div<HamburgerProps>`
  ${(props) =>
    props.$showhamburger === true || props.$showhamburger === null
      ? ""
      : "display: None;"}
  cursor: pointer;
  font-size: 20px;
  z-index: 1002;
  position: absolute;
  left: 20px;
  top: 75%;
  transform: translateY(-50%);
  color: ${(props) => props.color};
`;

const MobileMenu = styled.div<MobileMenuProps>`
  transform: translateY(${(props) => (props.open ? "0" : "-200%")});
  transition: transform 0.3s ease-in-out;

  position: fixed;
  background-color: #f0f0f0;
  top: ${(props) => (props.$ismobile ? "75px" : "110px")};
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 999;
`;

const SidePanel = styled.div<{ open: boolean; backgroundColor: string }>`
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? "0" : "-100%")};
  width: 250px;
  height: 100vh;
  background-color: ${(props) => props.backgroundColor};
  z-index: 1500;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1400;
  display: ${({ open }) => (open ? "block" : "none")};
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? "1" : "0")};
`;

const MobileNavLinkItem = styled.li`
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 30px;
  width: 100%;
  padding: 10px 0;
  list-style-type: none;
`;

interface ContainerProps {
  backgroundColor?: string;
  paddingTop?: string;
}
const MainContainer = styled.div<ContainerProps>`
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : `132px`)};
  max-width: 700px;
  margin: 0 auto;
  background-color: ${(props) => {
    return props.backgroundColor ? props.backgroundColor : theme.lightTextGray;
  }};
  height: 100%;
  width: 100%;
`;

const BackgroundContainer = styled.div<ContainerProps>`
  min-height: 100vh;
  height: 100%;
  background-color: ${(props) => {
    return props.backgroundColor ? props.backgroundColor : theme.lightTextGray;
  }};
  width: 100%;
  z-index: -1;
`;

const CloseButtonContainer = styled.div`
  width: 24px;
  height: 24px;
  margin: 20px;
  cursor: pointer;
  align-self: flex-start;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const LogoutButton = styled.button`
  width: 100%;
  padding: 15px 0;
  background-color: #fff;
  color: #0a165a;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }

  img {
    width: 20px; /* Adjust icon size as needed */
    height: auto;
  }
`;

export {
  MobileNavLinkItem,
  MobileMenu,
  MainContainer,
  HamburgerMenu,
  SidePanel,
  NavLink,
  NavLinkItem,
  NavbarContainer,
  BackButton,
  BackgroundContainer,
  Overlay,
  CloseButtonContainer,
  LogoutButton,
  NavbarWrapper,
};
