import { useNavigate } from "react-router-dom";
import {
  LandingScreenContainer,
  LandingScreenSubContainer,
  LoadingBarFill,
  LoadingBarBorder,
  LoadingBarContainer,
  LoadingBarAndSubtextContainer,
  SubTextContainer,
  TitleContainer,
  IconContainer,
} from "./index.styles";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
  default as BricolageGrotesque,
  BricolageGrotesqueFontWeight,
} from "../shared/fonts/bricolage-grotesque";
import GenericBackground from "../shared/generic-background";
import theme from "../../utils/theme";
import { useEffect } from "react";

const WelcomePageComponent = () => {
  const navigate = useNavigate();
  const isMobile = useMobileDetect();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/login");
    }, 2400);

    return () => clearTimeout(timer);
  }, [navigate]);

  const fontSize = {
    fontSize: isMobile ? "60px" : "90px",
    letterSpacing: "0.0rem",
  };
  const fontSizeSubText = {
    fontSize: isMobile ? "14px" : "20px",
    letterSpacing: "0rem",
  };

  return (
    <>
      <GenericBackground isLanding />
      <LandingScreenContainer>
        <LandingScreenSubContainer>
          <TitleContainer>
            <IconContainer isMobile={isMobile}>
              <img
                src={"/assets/icons/landingIllustration.svg"}
                alt="LandingIllustration"
              />
            </IconContainer>
            <BricolageGrotesque
              fontWeight={BricolageGrotesqueFontWeight.Bold}
              style={{
                ...fontSize,
                color: theme.darkBlue,
                lineHeight: "1.1",
                whiteSpace: "pre-line",
                textAlign: "left",
                marginTop: "5px",
                width: "100%",
              }}
            >
              {isMobile
                ? `UoftHacks\nJudging\nPortal`
                : `UoftHacks Judging Portal`}
            </BricolageGrotesque>
          </TitleContainer>

          <LoadingBarAndSubtextContainer>
            <SubTextContainer>
              <BricolageGrotesque
                fontWeight={BricolageGrotesqueFontWeight.Light}
                style={{
                  ...fontSizeSubText,
                  color: theme.darkBlue,
                }}
              >
                The internal judging app for hackathons
              </BricolageGrotesque>
            </SubTextContainer>
            <LoadingBarContainer>
              <LoadingBarBorder>
                <LoadingBarFill />
              </LoadingBarBorder>
            </LoadingBarContainer>
          </LoadingBarAndSubtextContainer>
        </LandingScreenSubContainer>
      </LandingScreenContainer>
    </>
  );
};

// @ts-ignore
export default WelcomePageComponent;
