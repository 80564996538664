import styled from "styled-components";
import Inter from "../../shared/fonts/inter";

const MarkedProjectsContainer = styled.div`
  margin: 10px 20px 20px 20px;
`;

const MarkedProjectsCollectionContainer = styled.div`
  border: 2px solid #ebebea;
  background-color: #f3f4f5;
  border-radius: 12px;
  max-height: 300px;
  overflow-y: auto;
`;

const ProjectItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 10px 10px;
  margin: 2px;
  border-bottom: 1px solid #ebebea;
  background-color: #fff;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const ProjectName = styled(Inter)`
  font-size: 16px;
  color: #000;
`;

const ProjectRatingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RatingCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 2px solid #8c9aea;
  border-radius: 50%;
  color: #0a165a;
  font-size: 14px;
  font-weight: bold;
`;

const SeeAllButton = styled.button`
  width: 100%;
  padding: 15px;
  border: none;
  color: #0a165a;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
`;

export {
  MarkedProjectsContainer,
  MarkedProjectsCollectionContainer,
  ProjectItem,
  ProjectName,
  ProjectRatingContainer,
  RatingCircle,
  SeeAllButton,
};
