import React from "react";
import { Navbar } from "../shared/nav-bar-component";
import Inter from "../shared/fonts/inter";
import { useNavigate } from "react-router-dom";
import {
  ListContainer,
  SearchInput,
  TitleElement,
  ScoreElement,
  ProjectItem,
  ScrollProtection,
  ConfigContainer,
  ProjectRatingContainer,
  RatingCircle,
  IconWrapper,
} from "./index.styles";
import { MainContainer } from "../../components/shared/nav-bar-component/index.styles";
import { NavigationTabComponent } from "./navigation-tab-component";

function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
}

type ProjectListProps = {
  title: string;
  projects: any[];
};

const ProjectListComponent: React.FC<ProjectListProps> = ({
  title,
  projects,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  React.useEffect(() => {
    const preventPullToRefresh = (e: any) => {
      if (window.scrollY < 1) {
        e.preventDefault();
        window.scrollTo(0, 2);
      }
    };

    document.addEventListener("touchmove", preventPullToRefresh, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchmove", preventPullToRefresh);
    };
  }, []);

  const fontSize = {
    fontSize: "17px",
  };
  const emptyPrompt = {
    color: "#898989",
    fontSize: "17px",
  };
  const navigate = useNavigate();

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <ScrollProtection>
      <Navbar
        showbutton={true}
        popStack={"/home"}
        title={"All Projects"}
        hamburger={false}
      >
        <ConfigContainer>
          <SearchInput
            type="text"
            placeholder="Search Project Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <NavigationTabComponent currentProjectTab={title} />
        </ConfigContainer>
      </Navbar>
      <MainContainer paddingTop={"230px"}>
        <ListContainer>
          {filteredProjects.length >= 1 &&
            filteredProjects
              .filter((project) =>
                project.name.toLowerCase().includes(searchTerm.toLowerCase()),
              )
              .sort((a, b) => {
                // sort by score in descending order if the title is "Completed"
                if (title === "Graded") {
                  return b.score - a.score;
                } else if (title === "Submitted Projects") {
                  return b.overallScore - a.overallScore;
                } else {
                  return 0;
                }
              })
              .map((project) => {
                const projectUrl = `/submissions/project/${project.id}`;
                const overallScore =
                  project.overallScore === undefined ? 0 : project.overallScore;
                const roundedOverallScore = Math.round(overallScore * 10) / 10;
                const overallScoreToOneDecimal = Number.isInteger(
                  roundedOverallScore,
                )
                  ? roundedOverallScore
                  : roundedOverallScore?.toFixed(1);
                return (
                  <ProjectItem
                    onClick={() => navigate(projectUrl)}
                    key={project.id}
                  >
                    <TitleElement>
                      {title === "Ungraded" && (
                        <IconWrapper>
                          <img
                            src={"/assets/icons/pendingProject.svg"}
                            alt={"pending project"}
                          />
                        </IconWrapper>
                      )}
                      <Inter style={fontSize}>
                        {truncateText(project.name, 20)}
                      </Inter>
                    </TitleElement>
                    {(title === "Graded" || title === "Your top 10") && (
                      <ProjectRatingContainer>
                        <RatingCircle>
                          <Inter style={fontSize}>{project.score}</Inter>
                        </RatingCircle>
                      </ProjectRatingContainer>
                    )}{" "}
                    {title === "Submitted Projects" && (
                      <ScoreElement>
                        <Inter style={fontSize}>
                          {overallScoreToOneDecimal}
                        </Inter>
                      </ScoreElement>
                    )}{" "}
                  </ProjectItem>
                );
              })}
          {filteredProjects.length < 1 && (
            <ProjectItem $backgroundcolor="white">
              <Inter style={emptyPrompt}> Nothing to see here!</Inter>
            </ProjectItem>
          )}
        </ListContainer>
      </MainContainer>
    </ScrollProtection>
  );
};

export default ProjectListComponent;
