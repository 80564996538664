import React from "react";
import { useNavigate } from "react-router-dom";
import { Project } from "../../utils/types";
import {
  PromptContainer,
  PromptMessage,
  ButtonContainer,
} from "./index.styles";
import { NavigateButton } from "../shared/buttons/index.styles";

import {
  ScoreFractionContainer,
  AverageScore,
  TotalScore,
} from "../project-scoring-component/index.styles";

type StatusProps = {
  success: boolean;
  project: Project;
};

const SubmitStatusComponent: React.FC<StatusProps> = ({ success, project }) => {
  const navigate = useNavigate();

  return success ? (
    <PromptContainer $issuccess={true}>
      <PromptMessage>Score submitted!</PromptMessage>
      <PromptMessage>{project.name}'s score has been updated to:</PromptMessage>
      <ScoreFractionContainer>
        <AverageScore $invertcolour={false}>{project.score}</AverageScore>
        <TotalScore $invertcolour={false}>/ 10</TotalScore>
      </ScoreFractionContainer>
      <ButtonContainer>
        <NavigateButton onClick={() => navigate("/submissions/pending")}>
          Continue
        </NavigateButton>
      </ButtonContainer>
    </PromptContainer>
  ) : (
    <PromptContainer $issuccess={false}>
      <PromptMessage>
        Failed to submit the score for this project at this time. Please try
        again later.
      </PromptMessage>
      <ButtonContainer>
        <NavigateButton
          onClick={() => {
            const redirectUrl = `/submissions/project/${project.id}/scoring`;
            return navigate(redirectUrl);
          }}
        >
          Try Again
        </NavigateButton>
        <NavigateButton onClick={() => navigate("/home")}>
          Return Home
        </NavigateButton>
      </ButtonContainer>
    </PromptContainer>
  );
};

export default SubmitStatusComponent;
