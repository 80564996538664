import styled from "styled-components";
import Inter from "../../shared/fonts/inter";
import theme from "../../../utils/theme";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  background-color: transparent;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
`;

const CardImage = styled.img`
  border-radius: 12px 12px 0 0;
  width: 100%;
`;

const CardTitle = styled(Inter)`
  font-size: 16px;
  font-weight: bold;
  margin: 0px 0 0;
  padding: 0;
  color: ${theme.black};
`;

const CardPrompt = styled(Inter)`
  font-size: 12px;
  color: #7d7b7a;
  margin-top: 5px;
`;

const TitleContainer = styled.div`
  display: flex;
  margin: 5px;
  margin-left: 0px;
  img {
    margin: 0px;
    padding: 0px;
    width: 20px;
  }
`;

export { CardContainer, CardImage, CardTitle, CardPrompt, TitleContainer };
