import {
  CardContainer,
  CardImage,
  CardTitle,
  CardPrompt,
  TitleContainer,
} from "./index.styles";
import React from "react";
interface QuickNavComponentProps {
  svgSource: string;
  pageName: string;
  helperPrompt: string;
  onClick: () => void;
}
export const QuickNavComponent = ({
  svgSource,
  pageName,
  helperPrompt,
  onClick,
}: QuickNavComponentProps) => {
  return (
    <CardContainer onClick={onClick}>
      <CardImage src={svgSource} alt={`${pageName} illustration`} />
      <TitleContainer>
        <CardTitle>{pageName}</CardTitle>
        <img src={"/assets/icons/arrowRight.svg"} alt={"right arrow"} />
      </TitleContainer>
      <CardPrompt>{helperPrompt}</CardPrompt>
    </CardContainer>
  );
};
