import styled from "styled-components";
import theme from "../../utils/theme";

const Prompt = styled.p`
  color: black;
  max-width: 640px;
  width: 80%;
  text-align: left;
`;

const QRCodeButton = styled.button`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${theme.darkBlue};
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  cursor: pointer;
`;

const ProjectsNavigationContainer = styled.div`
  margin: 20px;
  background-color: #e7eafc;
  border: 2px solid #8c9aea;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`;

const IllustrationOverlay = styled.img`
  width: 100%;
  object-fit: cover;
`;

const ProjectNavButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #0a165a;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #8c9aea;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: #8c9aea;
    color: #fff;
  }

  img {
    margin-left: 10px;
    width: 16px;
    height: auto;
  }
`;

const GridContainer = styled.div<{ isMobile?: boolean | null }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isMobile ? `repeat(2, 1fr)` : `repeat(2, 1fr)`};
  gap: 5px;
  place-items: center;
  margin: 20px;
`;

export {
  Prompt,
  QRCodeButton,
  ProjectNavButton,
  ProjectsNavigationContainer,
  IllustrationOverlay,
  GridContainer,
};
