import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Schedule from "./pages/schedule";
import JudgingCriteria from "./pages/judging-criteria";
import Results from "./pages/results";
import ProjectList from "./pages/project-list";
import ProjectOverview from "./pages/project-overview";
import ProjectScoring from "./pages/project-scoring";
import SubmitStatus from "./pages/score-submit-status";
import WelcomePage from "./pages/welcome";
import UserSelectPage from "./pages/user-select";

import { Provider } from "react-redux";
import store from "./store";
import LoadResultsData from "./components/wrappers/data-loaders/ResultLoader";
import LoadProjectsData from "./components/wrappers/data-loaders/ProjectsLoader";
import Logout from "./components/logout-component";
import ProtectedRoute from "./components/wrappers/route-guard";
import AuthGuard from "./components/wrappers/auth-guard";
import LoadChallengeInfo from "./components/wrappers/data-loaders/ChallengeInfoLoader";
import { BackgroundContainer } from "./components/shared/nav-bar-component/index.styles";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  var redirect = "/login";

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                mainMenu={true}
              >
                <WelcomePage />
              </AuthGuard>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/logout"
            element={<Logout setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path="/user-select"
            element={
              <ProtectedRoute pinCheck={true} redirect={redirect}>
                <UserSelectPage setIsAuthenticated={setIsAuthenticated} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer backgroundColor="#fff">
                  <LoadChallengeInfo>
                    <LoadResultsData>
                      <LoadProjectsData>
                        <Dashboard />
                      </LoadProjectsData>
                    </LoadResultsData>
                  </LoadChallengeInfo>
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/submissions/all"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProtectedRoute judgingClosed={true} redirect={"/home"}>
                    <ProjectList title="Submitted Projects" />
                  </ProtectedRoute>
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/submissions/pending"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProtectedRoute judging={true} redirect={"/home"}>
                    <ProjectList title="Ungraded" />
                  </ProtectedRoute>
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/submissions/completed"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProtectedRoute judging={true} redirect={"/home"}>
                    <ProjectList title="Graded" />
                  </ProtectedRoute>
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/submissions/your-top-10"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProtectedRoute judging={true} redirect={"/home"}>
                    <ProjectList title="Your top 10" />
                  </ProtectedRoute>
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/submissions/favourites"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProtectedRoute judging={true} redirect={"/home"}>
                    <ProjectList title="Favourites" />
                  </ProtectedRoute>
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/submissions/project/:projectId"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProjectOverview />
                </BackgroundContainer>
              </AuthGuard>
            }
          />

          <Route
            path="/submissions/project/:projectId/scoring"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProjectScoring />
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/submissions/project/:projectId/scoring/:status"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <LoadProjectsData>
                    <SubmitStatus />
                  </LoadProjectsData>
                </BackgroundContainer>
              </AuthGuard>
            }
          />

          <Route
            path="/schedule"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <Schedule />
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/judging-criteria"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <JudgingCriteria />
                </BackgroundContainer>
              </AuthGuard>
            }
          />
          <Route
            path="/results"
            element={
              <AuthGuard
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              >
                <BackgroundContainer>
                  <ProtectedRoute results={true} redirect={"/home"}>
                    <Results />
                  </ProtectedRoute>
                </BackgroundContainer>
              </AuthGuard>
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
