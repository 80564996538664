import ProjectListComponent from "../../components/project-list-component";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/types";

type ProjectListProps = {
  title: string;
};

const ProjectList: React.FC<ProjectListProps> = ({ title }) => {
  const { pending, inProgress, completed } = useSelector(
    (state: RootState) => state.projects,
  );

  let projects: any[];
  switch (title.toLowerCase()) {
    case "ungraded":
      projects = pending;
      break;
    case "graded":
      projects = completed;
      break;
    case "your top 10":
      projects = [...completed].sort((a, b) => b.score - a.score).slice(0, 10);
      break;
    case "submitted projects":
      projects = [...pending, ...completed];
      break;
    default:
      projects = [];
  }

  return <ProjectListComponent title={title} projects={projects} />;
};

export default ProjectList;
