import styled, { keyframes } from "styled-components";
import theme from "../../utils/theme";

type StarProps = {
  isMobile?: boolean | null;
};

type IconContainerProps = {
  isMobile?: boolean | null;
};

const LandingScreenContainer = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  min-width: 350px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LandingScreenSubContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 80vw;
  height: 100vh;
`;

const TitleContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: clamp(10px, 50%, 200px);
  z-index: 2;
`;

const LoadingBarAndSubtextContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: clamp(50px, 50%, 100px);
  z-index: 100;
`;

const SubTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0%;
  color: #fcfeff;
`;

const loadingAnimation = keyframes`
    0% {
        width: 0%;
    }
    10% {
        width: 10%;
        transition-timing-function: ease-out;
    }
    25% {
        width: 40%;
        transition-timing-function: ease-in;
    }
    50% {
        width: 60%;
        transition-timing-function: ease-out;
    }
    75% {
        width: 80%;
        transition-timing-function: ease-in;
    }
    90% {
        width: 90%;
        transition-timing-function: ease-out;
    }
    95% {
        width: 92%;
        transition-timing-function: ease-in;
    }
    97% {
        width: 94%;
        transition-timing-function: ease-out;
    }
    98% {
        width: 95%;
        transition-timing-function: ease-in;
    }
    99% {
        width: 96%;
        transition-timing-function: ease-out;
    }
    100% {
        width: 100%;
        transition-timing-function: ease-in;
    }
`;

const LoadingBarContainer = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const LoadingBarBorder = styled.div`
  height: 100%;
  width: 100%;
  background-color: #eeeae7;
  border: 1px solid #29398f;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
`;

const LoadingBarFill = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #29398f;
  border-radius: 10px;
  animation: ${loadingAnimation} 2s linear forwards;
  top: 0;
  left: 0;
`;

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? "4px" : "8px")};

  img {
    width: ${({ isMobile }) => (isMobile ? "200px" : "400px")};
    height: auto;
  }
`;

export {
  LandingScreenContainer,
  LandingScreenSubContainer,
  TitleContainer,
  LoadingBarAndSubtextContainer,
  SubTextContainer,
  LoadingBarFill,
  LoadingBarBorder,
  LoadingBarContainer,
  IconContainer,
};
