import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/utils/types";
import {
  MarkedProjectsContainer,
  MarkedProjectsCollectionContainer,
  ProjectItem,
  ProjectName,
  ProjectRatingContainer,
  RatingCircle,
  SeeAllButton,
} from "./index.styles";
import Inter from "../../../components/shared/fonts/inter";

export const MarkedProjectsComponent = () => {
  const navigate = useNavigate();
  const markedProjects = useSelector(
    (state: RootState) => state.projects.completed,
  );

  const hasProjects = markedProjects.length > 0;

  const helperFontSize = {
    fontSize: "14px",
    textAlign: "left",
    color: "black",
    marginTop: "0px",
  };

  const projectNameStyle = {
    fontSize: "15px",
    margin: 0,
  };

  return (
    <MarkedProjectsContainer>
      {hasProjects ? (
        <MarkedProjectsCollectionContainer>
          {markedProjects.map((project, index) => (
            <ProjectItem
              onClick={() => navigate(`/submissions/project/${project.id}`)}
              key={index}
            >
              <ProjectName>
                <Inter style={projectNameStyle}>{project.name}</Inter>
              </ProjectName>
              <ProjectRatingContainer>
                <RatingCircle>
                  <Inter style={projectNameStyle}>{project.score}</Inter>
                </RatingCircle>
              </ProjectRatingContainer>
            </ProjectItem>
          ))}
          <SeeAllButton onClick={() => navigate("/submissions/completed")}>
            See All
          </SeeAllButton>
        </MarkedProjectsCollectionContainer>
      ) : (
        <Inter style={helperFontSize}>
          You haven’t marked any projects yet! Get marking :)
        </Inter>
      )}
    </MarkedProjectsContainer>
  );
};
