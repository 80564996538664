import { useNavigate } from "react-router-dom";
import { TabContainer, TabButton } from "./index.styles";
import Inter from "../../shared/fonts/inter";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { is } from "@babel/types";

interface NavigationTabComponent {
  currentProjectTab: string;
}

export const NavigationTabComponent = ({
  currentProjectTab,
}: NavigationTabComponent) => {
  const navigate = useNavigate();
  const isMobile = useMobileDetect();
  const tabsMapping: { [key: string]: string } = {
    Ungraded: "/submissions/pending",
    Graded: "/submissions/completed",
    "Your Top 10": "/submissions/your-top-10",
    Favourites: "/submissions/favourites",
  };

  const handleTabClick = (tab: string) => {
    if (tab.toLowerCase() === currentProjectTab.toLowerCase()) {
      return;
    }

    const path = tabsMapping[tab];

    navigate(path);
  };

  const tabInterStyle = {
    fontSize: isMobile ? "10px" : "14px",
    margin: 0,
    padding: 0,
  };

  return (
    <TabContainer>
      {Object.keys(tabsMapping).map((tab) => (
        <TabButton
          key={tab}
          active={currentProjectTab.toLowerCase() === tab.toLowerCase()}
          onClick={() => handleTabClick(tab)}
        >
          <Inter style={tabInterStyle}>{tab}</Inter>
        </TabButton>
      ))}
    </TabContainer>
  );
};
