import styled from "styled-components";

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
`;

const TabButton = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 10px 0;
  font-size: 16px;
  border: ${({ active }) =>
    active ? "2px solid #2A398C" : "2px solid transparent"};
  background-color: ${({ active }) => (active ? "#F3F4F5" : "white")};
  color: ${({ active }) => (active ? "#19191B" : "#7D7B7A")};
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export { TabContainer, TabButton };
